import clsx from 'clsx';

import useStyles, { RowStyled } from './styles';
import { RowProps } from './types';

const StripedRow = ({ bold = false, children }: RowProps) => {
  const classes = useStyles();
  return <RowStyled className={clsx({ [classes.boldRow]: bold })}>{children}</RowStyled>;
};

export default StripedRow;
