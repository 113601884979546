import { Skeleton } from '@mui/material';

import Table from '@experimental-components/Table';

import { RowStyled } from './styles';
import type { SkeletonProps } from './types';

const StripedSkeleton = ({ rows = 5 }: SkeletonProps) => {
  return (
    <Table.Body>
      {Array.from(Array(rows).keys()).map((item) => (
        <RowStyled key={item}>
          <Skeleton height={18} variant="text" width={120} />
          <Skeleton height={18} variant="text" width={80} />
        </RowStyled>
      ))}
    </Table.Body>
  );
};

export default StripedSkeleton;
