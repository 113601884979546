import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { useIsFetching } from 'react-query';
import { camelCase } from 'lodash';

import DateRangePickerV2 from '@experimental-components/MaterialPickersDaterangeV2';
import MultipleSelect from '@design-system/MultipleSelect';
import TooltipBase from '@design-system/Tooltip';

import { useFilters } from 'containers/ReportAverageTicket/hooks';

import ContentFiltersReports from 'components/ContentFiltersReports';

import useGetCatalogReportFilters from 'hooks/reports/useGetCatalogReportFilters';
import useLocalStorageFiltersReport from 'hooks/useFiltersReports';

import { getDateOptionsToReportsV3 } from 'utils/dateRanges';
import { rangesValues } from 'utils/dateRangesTyped';
import { formatTypesDates } from 'utils/times';
import theme from 'utils/theme';

import { TypeDefaultFilter, FilterProps } from './types';
import useStyles from './styles';

const Filters = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const reportFilters = useGetCatalogReportFilters({ enabled: true });
  const { setFilters } = useFilters();
  const isFetchingTickets = useIsFetching();
  const {
    filtersReports: localStorageFilters,
    setFilterReports: setLocalStorageFilters,
    removeFilters: removeLocalStorageFilters,
  } = useLocalStorageFiltersReport();

  const [valuesDate, setValuesDate] = useState(rangesValues.TODAY);
  const [brandsSelected, setBrandsSelected] = useState<TypeDefaultFilter>();
  const [providersSelected, setProvidersSelected] = useState<TypeDefaultFilter>();
  const [orderTypesSelected, setOrderTypesSelected] = useState<TypeDefaultFilter>();

  const startHours = '00:00:00';
  const endHours = '23:59:59';
  const catalogFilters = reportFilters.data?.data;
  const disableFilters = reportFilters.isFetching || Boolean(isFetchingTickets);

  useEffect(() => {
    if (catalogFilters) {
      const brandsInit = catalogFilters.brands.map((brand) => brand.uuid);
      const orderTypesInit = catalogFilters.orderTypes.map((orderType) => orderType?.uuid || orderType?.orderType);
      let providersInit = catalogFilters.providers;

      let values = {
        startDate: valuesDate[0],
        endDate: valuesDate[1],
        brands: brandsInit,
        providers: providersInit,
        orderTypes: orderTypesInit,
      };

      const initLocation = localStorageFilters?.location as string;

      if (localStorageFilters && initLocation.startsWith('/reports/average-ticket')) {
        const startDateFromStorage = localStorageFilters?.startDate as Dayjs;
        const endDateFromStorage = localStorageFilters?.endDate as Dayjs;
        setValuesDate([startDateFromStorage, endDateFromStorage]);

        providersInit = localStorageFilters?.providers as string[];
        values = {
          ...values,
          startDate: startDateFromStorage,
          endDate: endDateFromStorage,
          providers: providersInit,
        };
      }

      setBrandsSelected(brandsInit);
      setProvidersSelected(providersInit);
      setOrderTypesSelected(orderTypesInit);

      handleSetFilters({ ...values });
    }
  }, [catalogFilters]);

  useEffect(() => {
    return () => {
      reportFilters.remove();

      if (!history.location.pathname.includes('/reports/average-ticket')) {
        removeLocalStorageFilters();
      }
    };
  }, []);

  const handleSetFilters = ({
    startDate = valuesDate[0],
    endDate = valuesDate[1],
    brands = brandsSelected,
    providers = providersSelected,
    orderTypes = orderTypesSelected,
  }: FilterProps) => {
    const values = {
      startDate: `${dayjs(startDate).format(formatTypesDates.SEND_FORM)} ${startHours}`,
      endDate: `${dayjs(endDate).format(formatTypesDates.SEND_FORM)} ${endHours}`,
      brands,
      providers,
      orderTypes,
    };

    const valuesToStorage = {
      location: location.pathname,
      startDate: values.startDate,
      endDate: values.endDate,
      providers: values.providers,
    };

    setFilters((filters) => ({ ...filters, ...values }));
    setLocalStorageFilters(valuesToStorage);
  };

  const handleChangeRangeDate = (dates: Dayjs[]) => {
    const [startDate, endDate] = dates;
    setValuesDate([startDate, endDate]);
    handleSetFilters({ startDate, endDate });
  };

  const handleChangeBrands = (values: string[]) => {
    setBrandsSelected(values);
    handleSetFilters({ brands: values });
  };

  const handleChangeProviders = (values: string[]) => {
    setProvidersSelected(values);
    handleSetFilters({ providers: values });
  };

  const handleChangeOrderTypes = (values: string[]) => {
    setOrderTypesSelected(values);
    handleSetFilters({ orderTypes: values });
  };

  return (
    <ContentFiltersReports>
      <Box className={classes.wrapperTooltip}>
        <TooltipBase
          boxShadow={theme.shadows[1]}
          className={classes.tooltip}
          maxWidth={200}
          sizeIcon={16}
          title={t('reports:averageTicket.tooltips.date')}
        />
        <DateRangePickerV2
          disabled={disableFilters}
          isFullWidth
          label={t('common:forms.date')}
          maxNumDays={62}
          onChange={handleChangeRangeDate}
          ranges={getDateOptionsToReportsV3(t)}
          value={valuesDate}
        />
      </Box>

      <MultipleSelect
        allItemsSelectedPlaceholder={t('reports:filters.brands.seeAll')}
        disabled={disableFilters}
        hideLabelHelperText
        label={t('reports:filters.brands.label')}
        onChange={handleChangeBrands}
        options={catalogFilters?.brands.map((brand) => ({ label: brand.name, value: brand.uuid }))}
        optionsSelected={brandsSelected}
        placeholder={t('reports:filters.brands.placeholder')}
        selectAllPlaceholder={t('reports:filters.brands.seeAll')}
      />

      <MultipleSelect
        allItemsSelectedPlaceholder={t('reports:filters.providers.seeAll')}
        disabled={disableFilters}
        hideLabelHelperText
        label={t('reports:filters.providers.label')}
        onChange={handleChangeProviders}
        options={catalogFilters?.providers.map((provider) => ({
          label: t(`reports:channels.${camelCase(provider)}`),
          value: provider,
        }))}
        optionsSelected={providersSelected}
        placeholder={t('reports:filters.providers.placeholder')}
        selectAllPlaceholder={t('reports:filters.providers.seeAll')}
      />

      <Box className={classes.wrapperTooltip}>
        <TooltipBase
          boxShadow={theme.shadows[1]}
          className={classes.tooltip}
          maxWidth={260}
          sizeIcon={16}
          title={t('reports:averageTicket.tooltips.orderType')}
        />
        <MultipleSelect
          allItemsSelectedPlaceholder={t('reports:filters.orderTypes.seeAll')}
          disabled={disableFilters}
          hideLabelHelperText
          label={t('reports:filters.orderTypes.label')}
          onChange={handleChangeOrderTypes}
          options={catalogFilters?.orderTypes.map((item) => ({
            label: item.name,
            value: item.uuid || item.orderType,
            isRemoved: item?.isRemoved,
          }))}
          optionsSelected={orderTypesSelected}
          placeholder={t('reports:filters.orderTypes.placeholder')}
          selectAllPlaceholder={t('reports:filters.orderTypes.seeAll')}
        />
      </Box>
    </ContentFiltersReports>
  );
};

export default Filters;
