/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import camelCase from 'lodash/camelCase';

import TooltipBase from '@design-system/Tooltip';

import useGetCatalogReportFilters from 'hooks/reports/useGetCatalogReportFilters';

import { useFilters, useGetAverageTicketDetail } from 'containers/ReportAverageTicket/hooks';

import ReportTable from 'components/ReportsTable';
import TableCustomContainer from 'components/GeneralTable/TableContainer';
import PageError from 'components/PageError';
import DisplayMessage from 'components/DisplayMessageV2';

import { getHeaderTooltip } from 'utils/reports/averageTicket';
import theme from 'utils/theme';

import useStyles from './styles';

const Detailed = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { filters } = useFilters();
  const reportFilters = useGetCatalogReportFilters({ enabled: false });
  const {
    data,
    dataStatus,
    loadAverageTicketDetail,
    removeAverageTicketDetail,
    paginate,
  } = useGetAverageTicketDetail();

  useEffect(() => {
    if (reportFilters.data) {
      removeAverageTicketDetail();
      loadAverageTicketDetail();
    }
  }, [filters]);

  useEffect(() => {
    return () => {
      removeAverageTicketDetail();
    };
  }, []);

  if (dataStatus.error) {
    return (
      <Box mt={6}>
        <PageError
          id="averageTicketSummaryError"
          labelAction={t('common:buttons.retry')}
          message={t('reports:messages.reportError')}
          onAction={loadAverageTicketDetail}
        />
      </Box>
    );
  }

  if (!data?.pages[0].data.rows.length && !dataStatus.fetching && dataStatus.fetched) {
    return (
      <Box>
        <Box mt="10%">
          <DisplayMessage
            height="auto"
            id="modifiersGroupsOptionsNoData"
            message={t('reports:reportsEmptyMessage')}
            sizeIcon={120}
          />
        </Box>
      </Box>
    );
  }

  if ((dataStatus.fetching || !dataStatus.fetched || reportFilters.isFetching) && !dataStatus.isFetchingNextPage) {
    return (
      <Box mx={-4}>
        <ReportTable.Placeholders placeholderCols={6} withHeaders />
      </Box>
    );
  }

  return (
    <Box mx={-4}>
      <TableCustomContainer
        hasNextPage={dataStatus.hasNextPage}
        isFetchingNextPage={dataStatus.isFetchingNextPage}
        onIntersecting={paginate}
        stickyHeader
      >
        <ReportTable.Head>
          <ReportTable.Row>
            {data?.pages[0]?.data?.headers?.map((header) => (
              <ReportTable.HeadCell key={camelCase(header.name)}>
                <Box className={classes.alignCenter}>
                  {header.name}

                  <Box className={classes.alignCenter} pl={1}>
                    <TooltipBase
                      boxShadow={theme.shadows[1]}
                      fontSize={14}
                      maxWidth={200}
                      sizeIcon={15}
                      title={t(`reports:averageTicket.tooltips.${getHeaderTooltip(header.sorting)}`)}
                    />
                  </Box>
                </Box>
              </ReportTable.HeadCell>
            ))}
          </ReportTable.Row>
        </ReportTable.Head>

        <ReportTable.Body>
          {data?.pages.map((group, i) => (
            <React.Fragment key={i}>
              {group.data.rows.map((row) => (
                <ReportTable.Row key={row.uuid}>
                  {row?.content?.map((content, index) => (
                    <ReportTable.Cell key={`${content?.value}-${index}`}>
                      {`${content?.symbol ?? ''}${content?.value ?? '-'}`}
                    </ReportTable.Cell>
                  ))}
                </ReportTable.Row>
              ))}
            </React.Fragment>
          ))}

          {dataStatus.isFetchingNextPage && <ReportTable.Placeholders placeholderCols={6} />}
        </ReportTable.Body>
      </TableCustomContainer>
    </Box>
  );
};

export default Detailed;
