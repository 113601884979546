import {
  getReportsProductsSalesRequest,
  exportReportsToExcelRequest,
  getReportsProductDetailSalesRequest,
} from 'utils/api/baas/reports/product';
import {
  getCashRegisterSessionsRequest,
  getCashRegisterSessionsDetailsRequest,
  exportCashRegisterSessionsReportToExcelRequest,
  getCashRegisterSessionsDetailsAllRequest,
} from 'utils/api/baas/reports/cashRegister';

const PRODUCTS_SALES = 'products-sales';
const CASH_REGISTER = 'cash-register';
const PRODUCT_DETAILS_SALES = 'product-details-sales';
const CASH_REGISTER_ALL = 'cash-register-all';
const ORDERS = 'orders';
const PAYMENTS = 'payments';
const CANCELLATIONS = 'cancellations';
const DISCOUNTS = 'discounts';
const REPORTS_RESUME = 'resume';
const CLIENT_DATA = 'client-data';
const TRANSACTIONS_PAY = 'transactions-pay';
const USERS = 'users';
const ASSISTANCE = 'assistance';
const COSTS_AND_MARGINS = 'costs-and-margins';
const MODIFIERS = 'modifiers';
const MOVEMENTS_CASH = 'movements-cash';
const SALES_PER_TIME = 'sales-per-time';
const SALES_PER_CATEGORY = 'sales-per-category';
const AVERAGE_TICKET = 'average-ticket';

export const reportsTypes = {
  PRODUCTS_SALES,
  CASH_REGISTER,
  PRODUCT_DETAILS_SALES,
  CASH_REGISTER_ALL,
  ORDERS,
  PAYMENTS,
  CANCELLATIONS,
  DISCOUNTS,
  REPORTS_RESUME,
  CLIENT_DATA,
  TRANSACTIONS_PAY,
  USERS,
  ASSISTANCE,
  COSTS_AND_MARGINS,
  MODIFIERS,
  MOVEMENTS_CASH,
  SALES_PER_TIME,
  SALES_PER_CATEGORY,
  AVERAGE_TICKET,
};

export const reportsSessionsCalls = {
  [CASH_REGISTER]: getCashRegisterSessionsRequest,
};

export const reportDetailsCalls = {
  [CASH_REGISTER]: getCashRegisterSessionsDetailsRequest,
  [PRODUCT_DETAILS_SALES]: getReportsProductDetailSalesRequest,
  [CASH_REGISTER_ALL]: getCashRegisterSessionsDetailsAllRequest,
};

export const reportsCalls = {
  [PRODUCTS_SALES]: getReportsProductsSalesRequest,
  [CASH_REGISTER]: getCashRegisterSessionsDetailsRequest,
};

export const exportReportsCalls = {
  [PRODUCTS_SALES]: exportReportsToExcelRequest,
  [CASH_REGISTER]: exportCashRegisterSessionsReportToExcelRequest,
};

export function getReportsSessionsRequest(type, key, ...args) {
  return reportsSessionsCalls[type](key, ...args);
}

export function getReportsRequest(type, key, ...args) {
  return reportsCalls[type](key, ...args);
}

export function getReportDetailsRequest(type, key, ...args) {
  return reportDetailsCalls[type](key, ...args);
}

export function exportReportsRequest(type, key, ...args) {
  return exportReportsCalls[type](key, ...args);
}

export const paginationConfigDefaults = {
  pageSize: 250,
  page: 1,
};

const REPORT_FORMAT_LINES = 'lines';
const REPORT_FORMAT_TABLE = 'table';
const REPORT_FORMAT_LIST = 'list';
const REPORT_FORMAT_SUMMARY = 'summary';
const REPORT_FORMAT_DETAILED = 'detailed';

export const reportFormatTypes = {
  REPORT_FORMAT_LINES,
  REPORT_FORMAT_TABLE,
  REPORT_FORMAT_LIST,
  REPORT_FORMAT_SUMMARY,
  REPORT_FORMAT_DETAILED,
};

function convertDataY(data) {
  return data?.map((item) => {
    item.y = parseFloat(item?.y);

    return item;
  });
}

export function getDataGraph(currentData) {
  const currentDataTmp = JSON.parse(JSON.stringify(currentData));
  let dataGraph = [];

  dataGraph = currentDataTmp?.layers?.map((layer) => {
    layer.id = layer?.name;
    layer.color = '#fff';
    layer.data = convertDataY(layer?.data);

    return layer;
  });

  return dataGraph || [];
}

const fileNameReportDefault = 'reporte';

export const fileExportReportConfig = {
  type: 'application/vnd.ms-excel;charset=utf-8',
  fileName: fileNameReportDefault,
  reportsTypeNames: {
    [PRODUCTS_SALES]: 'reports:reportsNames.productSales',
    [CASH_REGISTER]: 'reports:reportsNames.cashRegister',
    [USERS]: 'reports:reportsNames.users',
  },
  fileExtension: '.xlsx',
};

const OPEN = 'OPEN';
const CLOSED = 'CLOSED';

export const statusCashiersSessionDetails = {
  OPEN,
  CLOSED,
};
