import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import TableRow from '@mui/material/TableRow';
import type { Theme } from '@mui/material/styles';

export const RowStyled = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    paddingBlock: theme.spacing(1.3),
    paddingInline: theme.spacing(4),
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.darkGrey[2],
    },
    '& > p': {
      fontSize: theme.typography.pxToRem(14),
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
  },

  wrapperSummary: {
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginInline: theme.spacing(-4),
  },

  boldRow: {
    '& > p': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

export default useStyles;
